<template>
  <div class="flex flex-col h-full">
    <h6>Verify with MBS</h6>
    <component
      :is="step"
      v-bind="$attrs"
      :bank-data="bankData"
      v-on="listeners"
    />
  </div>
</template>
<script>
export default {
  name: 'MyBankStatement',
  components: {
    statementRequest: () => import('./StatementRequest'),
    statementConfirmation: () => import('./StatementConfirmation')
  },
  data() {
    return {
      step: 'statementRequest',
      bankData: null,
    };
  },
  computed: {
    listeners() {
      return {
        'back': () => this.step = 'statementRequest',
        'select-different-method': () => this.$emit('back'),
        'request-statement': (data) => {
          this.step = 'statementConfirmation';
          this.bankData = data;
        },
        'confirm-statement': () => this.$emit('verify-mbs')
      };
    }
  }
};
</script>
